<div class="row">
    <div class="container">
        <div class="row row-cols align-items-md-center g-5 py-5 px-3">
            <div class="col d-flex flex-column align-items-start gap-2">
                <h2 class="fw-bold text-body-emphasis feature-heading">Get actionable results from online marketing in
                    one place.</h2>
                <ul class="list-unstyled mt-4">
                    <li class="d-flex align-items-center mb-3">
                        <img src="/assets/icons/cloud.svg" alt="cloud" class="mr-2" />
                        <ng-content select="#feature-1"></ng-content>
                    </li>
                    <li class="d-flex align-items-center mb-3">
                        <img src="/assets/icons/user-check.svg" alt="user-check" class="mr-2" />
                        <ng-content select="#feature-2"></ng-content>
                    </li>
                    <li class="d-flex align-items-center mb-3">
                        <img src="/assets/icons/thumbs-up.svg" alt="thumbs-up" class="mr-2" />
                        <ng-content select="#feature-3"></ng-content>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>