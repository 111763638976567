
export const termsAndConditionsExternalLink = (
  'https://mcanism.com/publisher-terms/'
);

export const termsAndConditionsContent = `
Performance Marketing Programme Agreement Terms

Between the following partners: MCANISM technology GmbH (German limited liability company), Registration number HRB 155518 at Cremon 11, 20457 Hamburg, Germany represented by the CEO Gunnar Militz and the client/publisher signing up for this programm.

Are agreeing to work together under the following conditions:

§1 subject matter of the agreement

§1.1 MCANISM operates a platform (hereinafter also called "network"), (via the domain mcanism.com), which enables partners registered with MCANISM to advertise their goods and services online (hereinafter called "advertiser") and/or to make their offers
- e.g. products or services - available for advertising purposes (those partners are hereinafter called "publisher").

§1.2 The network will offer the Advertiser a portfolio of publishers. The publisher will direct their traffic/inventory towards the Advertisers website.
Advertisers  and publishers will be able to work together and participate in the campaigns (offers).

§1.3 The subject of the cooperation are media campaigns. In this connection, publishers offer advertising spaces through which advertisers can place advertising media - such as banners or text links - to market their goods and services.

§2 Duties of participants

§2.1 By registering, the participant is given the opportunity to participate in the network as an advertiser and/or publisher.

§2.2 MCANISM or a third party commissioned by MCANISM is entitled to request (from the client) the submission of suitable proofs of identity,
e.g. valid proof of trade, extract from the commercial register and/or proof of identity.

§2.3 If an employee of a legal entity registers it as a participant, the legal entity must authorize the legal entity to do so in writing. The same applies if another third party (e.g. agency) opens an account on behalf of a participant. The power of attorney must be proven at MCANISM
´s request.

§2.4 The participants are obliged to provide MCANISM, as well as other participants, with all registration data (date and time of clicks) required by MCANISM for the campaign. Participants are obliged to keep their registration data and all information regarding their campaigns up to date at all times.

§3 Rights and duties as a publisher

§3.1 MCANISM offers the publisher campaigns of advertisers for which  the publisher can provide its advertising space to support the distribution of the advertiser's goods and services. However, the publisher has no claim to certain advertisers or a certain number of advertisers being made available to him.

§3.2 By applying for a campaign, the publisher accepts any additional, campaign specific terms and conditions of MCANISM or the advertiser stated on the platform.

§3.3 MCANISM can enable publishers - e.g. in the context of comparison spreadsheets or product data - to gain access to several advertisers who offer services in the corresponding category by participating in a campaign in which MCANISM acts as an aggregator.

§4 Running campaigns

§4.1 The advertiser and publisher must approve the application and offer to participate in a campaign. Through this an individual contract an individual contract is formed, and any additional conditions of participation become an integral part of this contract. There are no contractual relationships between the advertiser and the publisher.

§4.2 The individual contract contains specific information on the type and remuneration of the service to be provided to support the respective advertiser in the online sale of his goods and services, e.g. the purchase of goods or the use of services by a third party (sale) or the ordering of a newsletter (lead). A combination of call, click, lead, sale and view is possible.

§4.3 Advertisers as well as publishers and MCANISM are entitled to reject an application without giving reasons.

§4.4 The advertiser agrees to check the publishers that wish to participate in  a campaign within an examination period of four (4) weeks from the submission of the offer and to either reject or accept it. The decision to accept a publisher for the advertiser's campaign is made exclusively by the advertiser within the examination period. After expiry of the examination period, MCANISM is entitled to reject or accept the application for the advertiser without giving reasons. In the absence of activation by MCANISM, an application is considered rejected without further ado.

§5 Service
§5.1 MCANISM is entitled but not obliged to continuously develop the platform at its own discretion and to adapt it to technical innovations.

§5.2 The publisher acts in the advertiser's interest with regard to the type,  range and form of advertising for the advertiser's campaign and not in fulfilment of an obligation incumbent on MCANISM towards the advertiser. Publishers do not act as subcontractors for MCANISM.

§5.3 MCANISM is entitled to transfer its own services or parts thereof to third party service providers or contractors for independent fulfilment.

§6 invoicing
§6.1 MCANISM can charge the advertiser an installation fee for setting up a campaign, which is due upon conclusion of the contract. The amount of this setup fee is regulated under "Conditions".

§6.2 Possible performance models:
Pay-Per-Install (CPI)
Pay per click (CPC)
Pay Per View (CPM)
Pay-Per-Lead (CPL)
Pay-per-sale (CPS)
Cost-per-Install (CPI)
Click-out (custom model)
campaigns or a combination (hybrid) of the aforementioned campaign types.

§6.3 On the basis of the respective individual contracts, the advertiser is obliged to compensate MCANISM                   further. Compensation claims arise according to the terms of the individual contract depending on the campaign with generated valid clicks, views, leads or sales within the meaning of this contract. The net remuneration of MCANISM is determined by the net remuneration share of the publisher according to the remuneration stated in the respective campaign. There is an additional charge. This additional fee is regulated under "Conditions".

§6.4 In the case of Pay-Per-View campaigns, MCANISM will be credited the currently valid fixed amount according to the campaign plus the additional fee agreed according to "Conditions" or the corresponding amount will be debited to the advertiser's account at MCANISM, unless the views are only views for the so-called Post-View-Tracking. Billing is per 1,000 (one thousand) views.

§6.5 For Pay-Per-Click           campaigns, MCANISM will be paid the current fixed amount for each click according to  the campaign plus the additional fee agreed in "Conditions" and the resulting amount will be charged to the advertiser's account at MCANISM.

§6.6 The reservation and/or debiting of the advertiser's account with MCANISM does   not                  constitute                    an acknowledgement to the effect that the leads or sales are actually valid or all fully recorded. For pay-per-sale campaigns with a percentage fee, this is calculated on the net sales value of the goods or services (i.e. excluding ancillary services and VAT).

§6.7 The advertiser reserves the right to prove that the leads or sales logged by MCANISM are not valid leads or sales. The advertiser shall immediately validate the marked leads or sales via the platform.

§6.8 Unless otherwise agreed, MCANISM will set up a verification period for the advertiser in the case of marked leads or sales, after the expiry of which marked leads or sales are automatically regarded as verified, valid leads or sales. The duration of this validation obligation is 365 days (maximum).

§6.9 Upon MCANISM´s request, the advertiser will provide information about the views, clicks, calls, leads and sales verified and not verified by him and regarding his corresponding objections. In addition, the advertiser grants MCANISM the right to check the verifications carried out with regard to completeness and correctness of content. This proof can be provided by presenting suitable  documents, customer documents and/or log files. MCANISM is also entitled to have the advertiser's details checked by an auditor bound to secrecy during normal business hours by inspecting the advertiser's corresponding business documents.

§6.10 The advertiser agrees that the amount of the standard commission for views, clicks, calls, leads and sales for campaigns of the advertiser shall always be agreed with MCANISM in such a way that they correspond to at least the amount of the highest standard commission of comparable campaigns of the advertiser for other public and private networks.

§6.11 If the advertiser agrees better conditions with another public or private network during the period of his cooperation with MCANISM, these automatically also apply in relation to  MCANISM.

§6.12 The advertiser is also under an obligation to pay if a tracking failure or other malfunction is caused by him or one of his vicarious agents. In such a case, the value to be compensated is calculated on the basis of the average daily turnover of the last three (3) months. The full daily trading volume is due per interrupted day.

§6.13 The advertiser is obliged to inform MCANISM and the publishers about changes in tracking two (2) months before the change takes effect and to coordinate these with MCANISM. Should the advertiser change the tracking procedure (including the tracking hierarchy) without prior information and agreement with MCANISM, so that the tracking no longer functions correctly (tracking failure or other malfunction), the failure will subsequently be compensated by the advertiser to the publishers and MCANISM on the basis of the average earnings of the last three (3) months before the failure.

§7 Terms of payments / fees
§7.1 Credit balances on the advertiser's account with MCANISM do not bear interest.

§7.2 All invoices from MCANISM can be issued in electronic form and are due and payable thirty (30) days after receipt of the invoice. The advertiser is not entitled to deduct discounts. In the case of late payment, MCANISM reserves the right to claim both reminder fees and interest on arrears, as well as to stop the respective campaigns.

§7.3 If a partner receives payments to a bank account that is not part of the Sepa payment area, fees will be charged for these transfers. These fees will be deducted from the credit note of the corresponding publisher. For transfers to an account that does not belong to the sepa payment area, the fee is 1.75% (but at least 10 euros) of the total amount of the credited amount, unless otherwise agreed. The same applies to transfers made via 3rd party payment providers such as but not limited to Paypal® or Western Union®.

§8 term of a contract
§8.1 The contract is entered for an indefinite period.

§9 notice of termination
§9.1 The parties are authorized to terminate all contracts - this contract as well as all individual contracts - by giving six (6) months' notice to the end of the quarter.

§9.2 The right of extraordinary termination for an important reason remains reserved to the parties. An important reason exists in particular if essential contractual obligations, in particular the advertiser's obligations according to these General Terms and Conditions, are violated.

§9.3 Notice of termination under these provisions must be given in writing. Notification of deactivation of access is always possible without any special formal requirements.

§9.4 In the event of termination of the contract, access to the network will be deactivated. MCANISM is also  entitled to deactivate the participant's account and to inform the participants about this.

§10 Cooperation         with     partners   after contract termination with MCANISM
§10.1 Both parties acknowledge that this contract and the resulting cooperation with the participants involved was only possible due to MCANISM and that the participant will therefore in no way cooperate with the participants recruited via the network in the area of online advertising for a period of 12 months from the end of the cooperation between the participant and MCANISM.

§11 employment ban
§11.1 During the entire term of the present contract, the client will refrain from asking MCANISM employees, directly or indirectly, to terminate their employment for or contractual relationship with MCANISM, to take up employment for other companies or persons and/or to disclose information or work equipment. The same applies for a period of 6 months after the end of this contract.

§12 liability
§12.1 In the event of a breach of an essential contractual obligation caused by negligence, the liability is limited to the typically foreseeable damage.

§12.2 Moreover, MCANISM´s liability is limited to a maximum amount of € 5,000 per claim.

§12.3 The aforementioned limitations of liability do not apply if MCANISM´s liability is based on an intentional or grossly negligent breach of duty by MCANISM or on injury to life, body or health.

§12.4 The participant releases MCANISM and its partners from all claims for damages, liability claims, cease-and-desist letters, declarations of discontinuance of third parties and other claims as well as any related costs and expenses which have been caused by a negligent behaviour (also omission) of the participant. This applies in particular to a violation of copyright, trademark, competition and data protection rights or the rights of third parties.

§13 non-disclosure
§13.1 The parties agree to keep secret for an unlimited period after the end of the contract all operational and  other business information and findings of the other party made available to them in connection with this contract, which are designated as confidential or are recognizable under other circumstances as business or trade secrets of one party, and not to record or pass them on or otherwise use them - unless required to achieve the purpose of the contract.

§14 UN sales law
§14.1 German law shall apply to the exclusion of the UN Convention on Contracts for the International Sale of Goods.

§15 Severability Clause
§15.1 Should one or more provisions of these GTC be or become invalid, this shall not affect the validity of the remaining provisions. The invalid provisions shall be replaced by an appropriate provision that comes as close as possible to what the contracting parties wanted.

§16 commission
The commission will be listed at the offer terms and conditions page.
`.trim();
