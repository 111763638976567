<app-header-v2>
  <span class="text-black mr-3">Already have an account?</span>
  <button type="button" [routerLink]="['/auth/login']" class="btn btn-danger">Log In</button>
</app-header-v2>

<div class="row bg-radial align-items-center">
  <div class="col-md-8 offset-md-2">
    <div class="row">
      <div class="col-md-6 align-items-center d-flex">
        <app-features>
          <span id="feature-1">Register for free and enjoy dedicated, personalized service.</span>
          <span id="feature-2">Get started with a cloud-based platform and your own personal account manager.</span>
          <span id="feature-3">Join a trusted network where your success is our mission, with quick and reliable support.</span>
        </app-features>
      </div>

      <div class="col-md-6">
        <div class="login-box ml-auto">
          <div class="login-box-header pt-4">
            <h2 class="text-black pt-2 text-center">Publisher Sign Up</h2>
          </div>

          <div class="login-box-body">
            <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label>First Name</label>
                <input formControlName="firstName" type="text" class="form-control" placeholder="First Name"
                  [class.is-invalid]="submitted && signUpFormControls.firstName.errors" />
                <div *ngIf="submitted && signUpFormControls.firstName.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.firstName.errors.required">First Name is required</div>
                </div>
              </div>
              <div class="form-group">
                <label>Last Name</label>
                <input formControlName="lastName" type="text" class="form-control" placeholder="Last Name"
                  [class.is-invalid]="submitted && signUpFormControls.lastName.errors" />
                <div *ngIf="submitted && signUpFormControls.lastName.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.lastName.errors.required">Last Name is required</div>
                </div>
              </div>
              <div class="form-group mb-4">
                <label>Email</label>
                <input formControlName="email" type="text" class="form-control" placeholder="Email"
                  [class.is-invalid]="submitted && signUpFormControls.email.errors" />
                <div *ngIf="submitted && signUpFormControls.email.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.email.errors.required">Email is required</div>
                  <div *ngIf="signUpFormControls.email.errors.email">Email must be a valid email address</div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label>Password</label>
                <input formControlName="password" type="password" class="form-control" placeholder="Password"
                  [class.is-invalid]="submitted && signUpFormControls.password.errors" />
                <div *ngIf="submitted && signUpFormControls.password.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.password.errors.required">Password is required</div>
                  <div *ngIf="signUpFormControls.password.errors.capital">
                    Password should contain a capital letter<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.small">
                    Password should contain a small letter<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.number">
                    Password should contain a number<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.minlength">
                    Password should contain 8 or more characters<br>
                  </div>
                  <div *ngIf="signUpFormControls.password.errors.special">
                    Password should contain a special character ($&#64;!%*?&)
                  </div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label>Re-enter Password</label>
                <input formControlName="confirmPassword" type="password" class="form-control"
                  placeholder="Re-enter Password"
                  [class.is-invalid]="submitted && signUpFormControls.confirmPassword.errors" />
                <div *ngIf="submitted && signUpFormControls.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="signUpFormControls.confirmPassword.errors.required">Confirm Password is required</div>
                  <div *ngIf="signUpFormControls.confirmPassword.errors.mustMatch">Passwords must match</div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label class="control control-outline control-primary control--checkbox mt-4"
                  title="You need to read the terms and conditions before you can accept them">
                  I agree to the MCANISM <a target="_blank" [routerLink]="['/legal/terms-and-conditions']" href="">terms
                    and
                    conditions</a>

                  <input type="checkbox" formControlName="agreeToTermAndConditions" />

                  <div class="control__indicator"
                    [class.border-invalid]="submitted && signUpFormControls.agreeToTermAndConditions.errors">
                  </div>
                </label>
              </div>

              <div class="w-100">
                <button type="submit" class="btn btn-primary w-100">
                  <img src="/assets/icons/circle-check.svg" alt="loader" class="mr-2" />
                  Sign Up!
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<app-footer-v2></app-footer-v2>