import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { IndicatorService } from 'src/app/shared/services/indicator.service';
import { TitleService } from 'src/app/shared/services/title-service';

import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private loginSubscription: Subscription;
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', [Validators.required, Validators.minLength(5)]);

  loginForm = new UntypedFormGroup({
    email: this.email,
    password: this.password,

  });

  constructor(
    public router: Router,
    private readonly route: ActivatedRoute,
    private authService: AuthService,
    private indicators: IndicatorService,
    private coreService: CoreService,
    private titleService: TitleService,
  ) {
    if (this.coreService.getCurrentAccountId()) {
      this.router.navigate([this.nextUrl]);
    }
  }

  ngOnInit(): void {
    this.titleService.setTitle('Login');
  }

  get nextUrl() {
    return this.route.snapshot.queryParams.returnUrl || '/dashboard';
  }

  onSubmit() {
    const data = { ...this.loginForm.value };
    this.loginSubscription = this.authService.login(data).subscribe(
      () => this.router.navigate([this.nextUrl]),
      async (error) => {
        const nonFieldErrors = error.error?.non_field_errors;
        if (nonFieldErrors != null && nonFieldErrors[0] === 'E-mail is not verified.') {
          const { value } = await this.coreService.alert(
            nonFieldErrors[0],
            'Please click the link that was sent to your E-mail to complete the signup process. ' +
            'Please also check your spam folder. If you have not received your signup link you can send it again here.',
            {
              icon: 'warning',
              confirmButtonText: 'Resend E-mail',
              showCancelButton: true,
              cancelButtonText: 'Close',
            },
          );

          if (value) {
            this.resendVerificationEmail();
          }
        } else {
          this.indicators.indicateFailure(error);
        }
      },
    );
  }

  public async resendVerificationEmail() {
    await this.authService.resendVerificationEmail(this.email.value)
      .then(() => this.coreService.alert('Verification e-mail sent!', null, { icon: 'success' }))
      .catch(this.indicators.failureIndicator());
  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }
}
