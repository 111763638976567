<app-header-v2>
  <span class="text-black mr-3">Need an account? </span>
  <button type="button" [routerLink]="['/auth/sign-up']" class="btn btn-danger">Create Account</button>
</app-header-v2>

<div class="row bg-radial align-items-center">
  <div class="col-md-8 offset-md-2">
    <div class="row px-2">
      <div class="col-md-12">
        <div class="login-box mx-auto">
          <div class="login-box-header pt-4">
            <h2 class="text-black pt-2 text-center">Reset Your Password</h2>
          </div>
          <div class="login-box-body">
            <form [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-4">
                <label>Email</label>
                <input formControlName="email" type="text" class="form-control" placeholder="Email"
                  [class.is-invalid]="submitted && forgotPasswordFormControls.email.errors" />
                <div *ngIf="submitted && forgotPasswordFormControls.email.errors" class="invalid-feedback">
                  <div *ngIf="forgotPasswordFormControls.email.errors.required">
                    Email is required
                  </div>
                  <div *ngIf="forgotPasswordFormControls.email.errors.email">
                    Email must be a valid email address
                  </div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label>Re-enter Email</label>
                <input formControlName="confirmEmail" type="email" class="form-control" placeholder="Re-enter Email"
                  [class.is-invalid]="submitted && forgotPasswordFormControls.confirmEmail.errors" />
                <div *ngIf="submitted && forgotPasswordFormControls.confirmEmail.errors" class="invalid-feedback">
                  <div *ngIf="forgotPasswordFormControls.confirmEmail.errors.required">
                    Confirm Email is required
                  </div>
                  <div *ngIf="forgotPasswordFormControls.confirmEmail.errors.email">
                    Confirm Email must be a valid email address
                  </div>
                  <div *ngIf="forgotPasswordFormControls.confirmEmail.errors.mustMatch">
                    Both Email fields must match
                  </div>
                </div>
              </div>

              <div class="w-100">
                <button type="submit" class="btn btn-primary w-100">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-v2></app-footer-v2>
