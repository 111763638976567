import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { PasswordValidator } from 'src/app/shared/constants/auth';
import { MustMatch } from 'src/app/shared/helpers/must-match.validator';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { TitleService } from 'src/app/shared/services/title-service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss'],
})
export class NewPasswordComponent implements OnInit {
  public uid: string;
  public token: string;

  password = new UntypedFormControl('', [Validators.required]);
  confirmPassword = new UntypedFormControl('', [Validators.required]);
  newPasswordForm: UntypedFormGroup;
  submitted = false;

  constructor(
    private readonly route: ActivatedRoute,
    private authService: AuthService,
    private coreService: CoreService,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    private titleService: TitleService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.uid = params['uid'];
      this.token = params['token'];
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Reset Password');
    this.newPasswordForm = this.formBuilder.group({
      password: ['', PasswordValidator.rules],
      confirmPassword: ['', [Validators.required]],
    }, {
      validator: MustMatch('password', 'confirmPassword'),
    });
  }

  get newPasswordFormControls() { return this.newPasswordForm.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.newPasswordForm.invalid) {
      return;
    }

    const body = {
      uid: this.uid,
      token: this.token,
      new_password1: this.newPasswordForm.value.password,
      new_password2: this.newPasswordForm.value.confirmPassword,
    };

    this.authService.newPassword(body)
      .then(
        (data: any) => {
          this.coreService.showMessageAndRedirect(
            'Success',
            data.detail,
            'success',
            '/auth/login',
          );
        },
        () => {
          this.coreService.showMessageAndRedirect(
            'Error',
            'An Error Occured. Try Again',
            'error',
            '/auth/login',
          );
        });
  }

}
