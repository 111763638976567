<app-header-v2>
  <span class="text-black mr-3">Need an account? </span>
  <button type="button" [routerLink]="['/auth/sign-up']" class="btn btn-danger">Create Account</button>
</app-header-v2>

<div class="row bg-radial align-items-center">
  <div class="col-md-8 offset-md-2">
    <div class="row px-2">
      <div class="col-md-12">
        <div class="login-box mx-auto">
          <div class="login-box-header pt-4">
            <h2 class="text-black pt-2 text-center">Reset Your Password</h2>
          </div>
          <div class="login-box-body">
            <form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-4">
                <label>New Password</label>
                <input formControlName="password" type="password" class="form-control" placeholder="New Password"
                  [class.is-invalid]="submitted && newPasswordFormControls.password.errors" />
                <div *ngIf="submitted && newPasswordFormControls.password.errors" class="invalid-feedback">
                  <div *ngIf="newPasswordFormControls.password.errors.required">
                    Password is required
                  </div>
                  <div *ngIf="newPasswordFormControls.password.errors.capital">
                    Password should contain a capital letter<br>
                  </div>
                  <div *ngIf="newPasswordFormControls.password.errors.small">
                    Password should contain a small letter<br>
                  </div>
                  <div *ngIf="newPasswordFormControls.password.errors.number">
                    Password should contain a number<br>
                  </div>
                  <div *ngIf="newPasswordFormControls.password.errors.minlength">
                    Password should contain 8 or more characters<br>
                  </div>
                  <div *ngIf="newPasswordFormControls.password.errors.special">
                    Password should contain a special character ($&#64;!%*?&)
                  </div>
                </div>
              </div>

              <div class="form-group mb-4">
                <label>Re-enter New Password</label>
                <input formControlName="confirmPassword" type="password" class="form-control"
                  placeholder="Re-enter New Password"
                  [class.is-invalid]="submitted && newPasswordFormControls.confirmPassword.errors" />
                <div *ngIf="submitted && newPasswordFormControls.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="newPasswordFormControls.confirmPassword.errors.required">
                    Confirm Password is required
                  </div>
                  <div *ngIf="newPasswordFormControls.confirmPassword.errors.mustMatch">
                    Password fields do not match
                  </div>
                </div>
              </div>

              <div class="w-100">
                <button type="submit" class="btn btn-primary w-100">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer-v2></app-footer-v2>
