import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SignUpComponent } from './sign-up/sign-up.component';

import { NewPasswordComponent } from 'src/app/modules/auth//new-password/new-password.component';
import { AuthRoutingModule } from 'src/app/modules/auth/auth-routing.module';
import { ForgotPasswordComponent } from 'src/app/modules/auth/forgot-password/forgot-password.component';
import { LoginComponent } from 'src/app/modules/auth/login/login.component';
import { VerifyEmailComponent } from 'src/app/modules/auth/verify-email/verify-email.component';

import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from '../shared/shared.module';
import { FeaturesComponent } from './features/features.component';

@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    NewPasswordComponent,
    VerifyEmailComponent,
    FeaturesComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    ToastrModule.forRoot(),
    SharedModule
  ],
})
export class AuthModule { }
