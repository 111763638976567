import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from 'src/app/shared/services/auth.service';
import { CoreService } from 'src/app/shared/services/core.service';
import { TitleService } from 'src/app/shared/services/title-service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  public key;
  public status = false;
  public message;

  constructor(
    private readonly route: ActivatedRoute,
    public router: Router,
    private authService: AuthService,
    private coreService: CoreService,
    private titleService: TitleService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.key = params['key'];
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Verify Email');
    this.verifyEmail();
  }

  verifyEmail() {
    const body = {
    };
    this.authService.verifyEmail(this.key, body).then(
      () => {
        this.coreService.showMessageAndRedirect(
          'Success',
          'Successfully Confirmed Email Address, You Can Login to Your Account.',
          'success',
          '/auth/login',
        );
      },
      () => {
        this.coreService.showMessageAndRedirect(
          'Error',
          'Problem Verifying Account, Try Again.',
          'error',
          '/auth/login',
        );
      });
  }
}
