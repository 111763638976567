<app-header-v2>
  <span class="text-black mr-3">Need an account? </span>
  <button type="button" [routerLink]="['/auth/sign-up']" class="btn btn-danger">Create Account</button>
</app-header-v2>

<div class="row bg-radial align-items-center">
  <div class="col-md-8 offset-md-2">
    <div class="row">
      <div class="col-md-6">
        <app-features>
          <span id="feature-1">Enhance your affiliate marketing with personalized support and advanced tools.</span>
          <span id="feature-2">Connect with dedicated account managers for tailored growth solutions.</span>
          <span id="feature-3">Experience a network where your needs are our priority, trusted by industry leaders.</span>
        </app-features>
      </div>

      <div class="col-md-6">
        <div class="login-box ml-auto">
          <div class="login-box-header pt-4">
            <h2 class="text-black pt-2 text-center">Welcome Back!</h2>
          </div>
          <div class="login-box-body">
            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-4">
                <label>Email</label>
                <input [formControl]="email" type="text" class="form-control" placeholder="Email" />
              </div>
              <div class="form-group mb-4">
                <label>Password</label>
                <input [formControl]="password" type="password" class="form-control" placeholder="Password" />
              </div>
              <div class="w-100">
                <button type="submit" [disabled]="loginForm.invalid"
                  class="btn btn-primary w-100 d-flex align-items-center justify-content-center">
                  <img src="/assets/icons/circle-check.svg" alt="loader" class="mr-2" />
                  Log In
                </button>
              </div>
              <div class="password-reset-link text-center mt-4">
                <a [routerLink]="['/auth', 'forgot-password']" class="text-center">Forgot
                  Password?</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

<app-footer-v2></app-footer-v2>